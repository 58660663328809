// Auth
import React, { useEffect, useState } from 'react';
import styles from './Auth.module.sass';
import { useAuth } from 'hooks/useAuth';
import ContentExamples from './ContentExamples';
import {
  API_RESPONSE_STATUS,
  AUTH_STATUS,
  AuthFormState,
  AuthFormType,
} from 'types/auth';
import { getAuthRedirect } from 'components/containers/AuthProtection';
import { useRouter } from 'next/router';
import ROUTES from 'config/routes';
import useToast from 'components/common/Toast';
import AuthForm from './AuthForm';
import SoonaAndTrendSignature from 'components/common/SoonaAndTrendSignature';

export interface AuthProps {
  initialFormType: AuthFormType;
}

const AuthView: React.FC<AuthProps> = ({ initialFormType }) => {
  const router = useRouter();

  const [authFormType, setAuthFormType] =
    useState<AuthFormType>(initialFormType);

  const {
    authState: { status: authStatus, brand },
    login,
    googleLogin,
    signup,
  } = useAuth();
  const { triggerToast } = useToast();
  const [authForm, setAuthForm] = useState<AuthFormState>({
    email: '',
    password: '',
  });
  const { email, password } = authForm;

  useEffect(() => {
    if (authStatus === AUTH_STATUS.AUTHENTICATED && brand) {
      const redirect = getAuthRedirect() || ROUTES.ShowCase.getPath();
      if (!brand.firstCampaign) {
        // If brand has not created 1st campaign, push to Create Campaign Paywall
        router.push({
          pathname: ROUTES.CreateCampaign.getPath(),
        });
      } else {
        router.push({
          pathname: redirect,
          query: `latest_campaign`,
        });
      }
    }
  }, [authStatus, brand, router]);

  const handleGoogleSSO = async () => {
    const { status, message } = await googleLogin();
    if (status === API_RESPONSE_STATUS.ERROR) {
      // TODO: Set as auth error w/ useAuth hook
      //   setEmailError(message);
      triggerToast(message);
    }
  };

  const handleLogin = () => {
    login(email, password);
  };

  const handleSignUp = () => {
    signup(email, password);
  };

  return (
    <div className={styles.authView}>
      <ContentExamples />
      <div className={styles.authContainer}>
        {/* Soona + Trend logo */}
        <SoonaAndTrendSignature />
        <AuthForm
          authForm={authForm}
          authFormType={authFormType}
          setAuthForm={setAuthForm}
          setAuthFormType={setAuthFormType}
          onSignUp={handleSignUp}
          onLogin={handleLogin}
          onGoogleSSO={handleGoogleSSO}
        />
        <div className={styles.creatorAuth}>
          <p className="text-size-regular text-lineheight-normal text-align-center">
            Want to join as a creator?{' '}
            <a
              href="https://creators.trend.io"
              className="DM-Sans text-weight-bold"
            >
              Sign Up Here
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AuthView;
