import React from 'react';
import { NextPageWithLayout } from 'types/page';
import Loader from './index';
import styles from './Loader.module.sass';

const PageLoader: NextPageWithLayout = () => (
  <div className={styles.pageLoader}>
    <Loader />
  </div>
);

export default PageLoader;
